var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(!_vm.loaded)?[_vm._m(0)]:[(_vm.currentOrganized.winners && _vm.currentOrganized.winners.length===0)?[_c('div',{staticClass:"h-100 d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"text-center p-3"},[_c('h5',{staticClass:"font-size-24 m-3"},[_vm._v("Դուք դեռևս չունեք ներմուծված մասնակիցներ")]),_c('button',{staticClass:"btn btn-primary btn",on:{"click":_vm.cancelOrganize}},[_c('span',[_vm._v("Ընթացակարգը համարել չկայացած ")])])])])]:_c('div',{staticClass:"responsive-table"},[_c('table',{staticClass:"contract-preparation-table table table-borderless VueTables__table table table-striped table-border-radius m-0",staticStyle:{"border-collapse":"collapse","border-spacing":"0","width":"100% !important"}},[_vm._m(1),_c('tbody',{staticClass:"medium-scroll",staticStyle:{"min-height":"5vh","max-height":"40vh !important","overflow":"scroll"},attrs:{"id":"comparison-table"}},_vm._l((_vm.currentOrganized.winners),function(winner,winnerIndex){return _c('tr',{key:winnerIndex},[_c('td',[_vm._v(_vm._s(winnerIndex + 1))]),_c('td',[_vm._v(_vm._s(winner.name))]),_c('td',[_vm._v(_vm._s(winner.phone))]),_c('td',[_vm._v(_vm._s(winner.email))]),_c('td',[_c('button',{staticClass:"btn",class:winner.director ? 'btn-primary':'btn-secondary',attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return (() => {
                    _vm.$modal.show('winner-requirements-modal', winner)
                  }).apply(null, arguments)}}},[_c('span',[_vm._v("Լրացնել")])])]),_c('td',_vm._l((winner.won_lots),function(lot,lotIndex){return _c('span',{key:lot.toString() + lotIndex},[_vm._v(" "+_vm._s(lot.is_from_outside ? lot.procurement_plan.cpv_outside.name : lot.procurement_plan.cpv.name)),(lotIndex !== winner.won_lots.length-1)?_c('span',[_vm._v(",")]):_vm._e()])}),0),(Number.isInteger(_vm.getContractPrice(winner)))?_c('td',[_vm._v(" "+_vm._s(_vm.getContractPrice(winner))+"դր.")]):_c('td',[_vm._v(" "+_vm._s(_vm.getContractPrice(winner))+"դր.")]),_c('td',[_c('div',{staticClass:"row p-0 m-0 align-items-center justify-content-center"},[(_vm.getIsSigned(winner))?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 mb-2 justify-content-start"},[_vm._v(" Կնքված է ")])]):_vm._e(),_c('div',{staticClass:"col-12 pl-0 mb-2 text-primary justify-content-start"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return (() => {
                          _vm.$modal.show('new-editor-modal', {
                            action: 'download',
                            fileName: 'contract_offer',
                            participantGroup: winner,
                            editorTitle: 'Պայմանագիր կնքելու առաջարկ'
                          })
                        }).apply(null, arguments)}}},[_c('span',[_vm._v("Պայմանագրի առաջարկ")])])]),_c('div',{staticClass:"col-12 pl-0 mb-2 text-primary justify-content-start"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":winner.director===''},on:{"click":function($event){$event.preventDefault();return (() => {
                            _vm.$modal.show('new-editor-modal', {
                              action: 'download',
                              fileName: 'price_offer',
                              participantGroup: winner,
                              editorTitle: 'Գնային առաջարկ'
                            })
                          }).apply(null, arguments)}}},[_c('span',[_vm._v("Գնային առաջարկ")])])]),_c('div',{staticClass:"col-12 pl-0 justify-content-start"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":winner.director===''},on:{"click":function($event){$event.preventDefault();return (() => {
                          _vm.$modal.show('contract-modal', {index: winnerIndex+1, winner})
                        }).apply(null, arguments)}}},[_c('span',[_vm._v("Պայմանագիր")])])])])])])}),0)])])],_c('winner-requirements-modal',{on:{"updateComparisonData":_vm.resetData}}),_c('winner-lots-edit-modal',{on:{"updateComparisonData":_vm.resetData}}),_c('contract-request-modal',{on:{"updateComparisonData":_vm.resetData}}),_c('new-editor-modal'),_c('contract-modal'),_c('editor-modal')],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"text-center p-3"},[_c('div',{staticClass:"text-big text-primary spinner-border spinner-border-lg m-auto position-absolute",staticStyle:{"left":"0","right":"0","top":"0","bottom":"0"},attrs:{"role":"status"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"20px"}},[_vm._v("Հ/Հ")]),_c('th',[_vm._v("Մասնակցի անվանում")]),_c('th',[_vm._v("Մասնակցի հեռախոսահամար")]),_c('th',[_vm._v("Մասնակցի Էլ. հասցե")]),_c('th',[_vm._v("Մասնակցի վավերապայմաներ")]),_c('th',[_vm._v("Գնման առարկաներ")]),_c('th',[_vm._v("Պայմանագրի գումար")]),_c('th',[_vm._v("Փաստաթղթեր")])])])
}]

export { render, staticRenderFns }